import React from 'react'
import { graphql } from 'gatsby'

// not sure why it's not resolving for my local linter but need to run right now
// eslint-disable-next-line import/no-unresolved
import { titleCase } from 'title-case'

import BlocksRenderer from '../components/blocks-renderer'
import { Header } from '../components/shared/Text'
import ArticleLayout from '../components/news/ArticleLayout'

const ArticlePage = ({ data, pageContext }) => {
  const {
    strapiArticle: article,
    moreArticles: { nodes: moreArticles },
  } = data

  return (
    <ArticleLayout
      article={article}
      moreArticles={moreArticles}
      pageContext={pageContext}
    >
      <header className="mt-8 flex w-full flex-col gap-8 pt-14 text-center lg:px-52">
        <Header className="">{titleCase(article.title)}</Header>
      </header>
      <main className="lg:px-52">
        <BlocksRenderer blocks={article.blocks || []} />
      </main>
    </ArticleLayout>
  )
}

export const pageQuery = graphql`
  query ($slug: String, $locale: String) {
    strapiArticle(slug: { eq: $slug }) {
      id
      publishedAt
      slug
      title
      # we are not using description IN the article right now
      description
      blocks {
        ...Blocks
      }
      cover {
        alternativeText
        localFile {
          url
          childImageSharp {
            gatsbyImageData
          }
        }
      }
    }

    moreArticles: allStrapiArticle(
      filter: { locale: { eq: $locale }, publishedAt: { ne: null } }
      sort: { fields: createdAt, order: DESC }
    ) {
      nodes {
        ...ArticleCard
      }
    }
  }
`

export default ArticlePage
